export default function () {
  return {
    platform: localStorage.getItem("productline") || "amazon", // amazon,walmart,criteo,instacart
    sortPanelStatus: false, //控制下拉排序面板状态
    dashboardArray: [], //面板数据状态
    otherDashboardArray: [], //其他面板数据状态
    commonDashboardIds: [], //common类型勾选Ids
    profileList: [], //list
    profileIds: [], //id集合
    shareProfileIds: [], //share id集合
    campaignTypes: [], //id集合
    shareCampaignTypes: [], //share id集合
    shareSelectSovBrands: [],
    campaignTagsAll: [], //所有的campaignTags(包含子级)
    campaignTags: [], //tags集合
    noTagCampaigns: false, //No campaignTag
    noTagCampaignLists: [], //No campaignTag 集合
    shareCampaignTags: [], //share tags集合
    portfolio: [], //portfolio集合
    analyticsProfileId: [],
    advertiserIds: [],
    attrAdvertiserIds: [],
    sharePreference: "acos",
    showContentPer: [],
    showContentEff: [],
    homeProducts: 2, //home products表默认选择2
    homeBudget: 2, // budget表默认选择2
    homePrescriptiveInsightByTag: 1,
    homeBrandSOV: 1,
    homeBrandSOVCategoryValue: [],
    homeBrandSOVSort: "shareOfShelf",
    homeCampaignTagPer: 1,
    homePlacementPerf: "SP",
    homeTop20: "sales",
    homeCampaignGoal: 1,
    homeNonBid: 1,
    homeKeywords: 1,
    homeKeywordsColumn: "Spend",
    homeKeywordsTagPerf: [],
    homeActualSpend: 1,
    homePageTypePerformance: "SP",
    homePlatformPerformance: "SP",
    homeCampaignInsufficient: 1,
    is3p: false,
    homeTopWidgetsData: [],
    homeIsAllWidgetsLoaded: false, //home页面所有widgets是否加载完成
    productCenterIsAllWidgetsLoaded: false,
    channelType: 0
  }
}
