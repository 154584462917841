import Main from "@pacvue/frame/layout/Main.vue"
import routerRebuild from "@pacvue/frame/routerRebuild"
const routes = [
  {
    path: "/",
    component: Main,
    meta: {
      auth: true,
      id: -1
    },
    redirect: "/Home",
    children: [
      {
        path: "/Home",
        component: () => import("@!/views/Home/index.vue"),
        meta: {
          alias: "Home"
        },
        name: "Home"
      },
      {
        path: "/Share/Index",
        component: () => import("@!/views/Share/sharePage/index.vue")
      },
      {
        path: "/Training",
        name: "Training Doc & Video",
        component: () => import("@/views/Training/index.vue"),
        meta: { title: "Training Doc & Video" }
      },
      {
        path: "/ProductCenter/ProductDashboard",
        component: () => import("@!/views/ProductCenter/ProductDashboard/index.vue"),
        name: "ProductDashboard",
        meta: { title: "ProductDashboard", id: 2, showCompareCheck: false, alias: "PC_PC" }
      },
      {
        path: "/ProductCenter/ProductListing",
        component: () => import("@!/views/ProductCenter/ProductListing/index.vue"),
        name: "Product Listing",
        meta: { title: "ProductDashboard", id: 2, isShowTime: true, showCompareCheck: false, alias: "PC_SR" }
      },
      {
        path: "/ProductCenter/ProductDetail",
        component: () => import("@!/views/ProductCenter/ProductDetail.vue"),
        name: "Product Detail",
        meta: { parent: "Product Listing", showCompareCheck: false, alias: "PC_SR" }
      },
      {
        path: "/ProductCenter/ProductNonVC",
        component: () => import("@!/views/ProductCenter/ProductDashboard/ProductNonVc.vue"),
        name: "ProductNonVC",
        meta: { parent: "ProductDashboard", isShowTime: true, alias: "ED" }
      },
      {
        path: "/Report/index",
        component: () => import("@!/views/Report/DefaultReport/DefaultReport.vue"),
        name: "Default Report"
      },
      {
        path: "/Report/MyReport",
        component: () => import("@!/views/Report/MyReport/index.vue"),
        name: "My Report",
        meta: {
          alias: "Report_DR"
        }
      },
      {
        path: "/createMyReport",
        component: () => import("@!/views/Report/MyReport/createMyReport.vue"),
        name: "create My Report",
        meta: { alias: "Report_My" }
      },
      {
        path: "/viewHistoryReport",
        component: () => import("@!/views/Report/MyReport/viewHistoryReport.vue"),
        name: "View History",
        meta: { parent: "My Report", alias: "Report_My" }
      },
      {
        path: "/Report/KeywordPerformanceReport",
        component: () => import("@!/views/Report/DefaultReport/KeywordPerformanceReport.vue"),
        name: "Keyword Report",
        meta: { parent: "Default Report", alias: "Report_DR" }
      },
      {
        path: "/Report/AccountProfilesSpendsReport",
        component: () => import("@!/views/Report/DefaultReport/AccountProfilesSpendsReport.vue"),
        name: "Account Profile Spend Report",
        meta: { parent: "Default Report", alias: "Report_DR" }
      },
      {
        path: "/Report/ItemReport",
        component: () => import("@!/views/Report/DefaultReport/ItemReport.vue"),
        name: "Item Report",
        meta: { parent: "Default Report", alias: "Report_DR" }
      },
      {
        path: "/Report/ItemHealthReport",
        component: () => import("@!/views/Report/DefaultReport/ItemHealthReport.vue"),
        name: "Item Health Report",
        meta: { parent: "Default Report" }
      },
      {
        path: "/Report/ProfileReport",
        component: () => import("@!/views/Report/DefaultReport/ProfileReport.vue"),
        name: "Profile Report",
        meta: { parent: "Default Report" }
      },
      {
        path: "/Report/CampaignReport",
        component: () => import("@!/views/Report/DefaultReport/CampaignReport.vue"),
        name: "Campaign Report",
        meta: { parent: "Default Report" }
      },
      {
        path: "/Report/PageTypeReport",
        component: () => import("@!/views/Report/DefaultReport/PageTypeReport.vue"),
        name: "Pagetype Report",
        meta: { parent: "Default Report" }
      },
      {
        path: "/Report/PlacementReport",
        component: () => import("@!/views/Report/DefaultReport/PlacementReport.vue"),
        name: "Placement Report",
        meta: { parent: "Default Report" }
      },
      {
        path: "/Report/PlatformReport",
        component: () => import("@!/views/Report/DefaultReport/PlatformReport.vue"),
        name: "Platform Report",
        meta: { parent: "Default Report" }
      },
      {
        path: "/Report/CampaignHourlyReport",
        component: () => import("@!/views/Report/DefaultReport/CampaignHourlyReport.vue"),
        name: "Campaign Hourly Report",
        meta: { parent: "Default Report" }
      },
      {
        path: "/Report/CampaignOutofBudgetReport",
        component: () => import("@!/views/Report/DefaultReport/CampaignOutofBudgetReport.vue"),
        name: "Campaign Out of Daily Budget Report",
        meta: { parent: "Default Report" }
      },
      {
        path: "/Report/PortfolioCampaignTagReport",
        component: () => import("@!/views/Report/DefaultReport/PortfolioCampaignTagReport.vue"),
        name: "Campaign Tag Report",
        meta: { parent: "Default Report" }
      },
      {
        path: "/Report/ProfiteroReport",
        component: () => import("@!/views/Report/DefaultReport/components/ProfiteroReport/index.vue"),
        name: "Profitero Report",
        meta: { isShowTime: true, parent: "Default Report" }
      },
      {
        path: "/Report/ItemBrandHaloReport",
        component: () => import("@!/views/Report/DefaultReport/components/ItemBrandHaloReport/index.vue"),
        name: "Item Brand Halo Report",
        meta: { parent: "Default Report" }
      },
      {
        path: "/Analysis/Competitive",
        component: () => import("@!/views/CompetitorAnalysis/index.vue"),
        name: "Competitive",
        meta: {
          alias: "CA_Competitive"
        }
      },
      {
        path: "/Analysis/SOVDashboard",
        component: () => import("@!/views/CompetitorAnalysis/SOVDashboard.vue"),
        name: "View SOV",
        meta: {
          parent: "Competitive",
          isShowTime: true,
          alias: "CA_Competitive"
        }
      },
      {
        path: "/Share/SOVDashboard",
        component: () => import("@!/views/Share/sharePage/index.vue"),
        meta: {
          title: "test 1",
          id: 1,
          isShowTime: false,
          showCompareCheck: false,
          breadcrumb: [
            {
              name: "Price Tracker",
              link: "/Price Tracker/ShareDashboard"
            }
          ]
        }
      },
      {
        path: "/Share/SOVBrand",
        component: () => import("@!/views/Share/sharePage/index.vue")
      },
      {
        path: "/Share/SOVKeyword",
        component: () => import("@!/views/Share/sharePage/index.vue")
      },
      {
        path: "/Share/SOVAsin",
        component: () => import("@!/views/Share/sharePage/index.vue")
      },
      {
        path: "/Analysis/SOVBrand",
        component: () => import("@!/views/CompetitorAnalysis/SOVBrand.vue"),
        meta: {
          parent: "Competitive",
          isShowTime: true
        },
        name: "SOVBrand"
      },
      {
        path: "/Analysis/SOVKeyword",
        component: () => import("@!/views/CompetitorAnalysis/SOVKeyword.vue"),
        meta: {
          parent: "Competitive",
          isShowTime: true,
          alias: "CA_Competitive"
        },
        name: "SOVKeyword"
      },
      {
        path: "/Analysis/SOVAsin",
        component: () => import("@!/views/CompetitorAnalysis/SOVAsin.vue"),
        meta: {
          parent: "Competitive",
          isShowTime: true,
          alias: "CA_Competitive"
        },
        name: "SOVItem"
      },
      {
        path: "/Analysis/SOVInsight",
        name: "SOVInsight",
        component: () => import("@!/views/CompetitorAnalysis/SOVInsight.vue"),
        meta: {
          parent: "Competitive",
          isShowTime: true,
          alias: "CA_Competitive"
        }
      },
      {
        path: "/Analysis/ManageKeywordTag",
        component: () => import("@!/views/CompetitorAnalysis/ManageKeywordTag.vue"),
        name: "Manage SOV Keyword Tag",
        meta: {
          parent: "Competitive",
          isShowTime: false,
          alias: "CA_Competitive"
        }
      },
      {
        path: "/Analysis/CompetitorAsinAnalysis",
        component: () => import("@!/views/CompetitorAnalysis/AsinDetail.vue"),
        name: "Item SOV Analysis",
        meta: {
          parent: "Competitive",
          isShowTime: true,
          alias: "CA_Competitive"
        }
      },
      {
        path: "/Analysis/CategoryLog",
        component: () => import("@!/views/CompetitorAnalysis/CategoryLog.vue"),
        name: "CategoryLog",
        meta: {
          parent: "Competitive",
          isShowTime: true
        }
      },
      {
        path: "/Share/Analysis/CompetitorAsinAnalysis",
        component: () => import("@!/views/CompetitorAnalysis/AsinDetail.vue"),
        name: "Share Item SOV Analysis",
        meta: {
          parent: "View SOV",
          isShowTime: true,
          alias: ""
        }
      },
      {
        path: "/Analysis/BrandsMapping",
        component: () => import("@!/views/CompetitorAnalysis/BrandsMapping.vue"),
        meta: {
          parent: "Competitive",
          isShowTime: false,
          alias: "CA_Competitive"
        },
        name: "BrandsMapping"
      },
      {
        path: "/Analysis/KeywordDetail",
        component: () => import("@!/views/CompetitorAnalysis/KeywordDetailComp.vue"),
        name: "KeywordDetail",
        meta: {
          parent: "Competitive",
          isShowTime: true,
          showCompareCheck: false,
          alias: "CA_Competitive",
          breadcrumb: [
            {
              name: "Competitive",
              link: "/"
            },
            {
              name: "KeywordDetail"
            }
          ]
        }
      },
      {
        path: "/Analysis/Redirect",
        redirect: "/Analysis/KeywordDetail"
      },
      {
        path: "/PriceTracker/PriceTrackerDetail",
        component: () => import("@/views/PriceTracker/detail.vue"),
        meta: {
          title: "Product Detail",
          id: 1,
          isShowTime: true,
          showCompareCheck: true,
          alias: "PT",
          breadcrumb: [
            {
              name: "PriceTracker",
              link: "/PriceTracker/Home"
            },
            {
              name: "Product Detail",
              link: "/PriceTracker/PriceTrackerDetail"
            }
          ]
        }
      },
      {
        path: "/Research/KeywordsCopy",
        component: () => import("@!/views/Optimization/KeywordResearch/index.vue"),
        name: "Keyword Research",
        meta: {
          alias: "Opt_KR"
        }
      },
      {
        path: "/Research/ImprShare",
        component: () => import("@!/views/Optimization/ImprShare/index.vue"),
        name: "Impr Share",
        meta: {
          alias: "Opt_KR"
        }
      },
      {
        path: "/Research/BidLandScape",
        component: () => import("@!/views/Research/BidLandScape/index.vue"),
        name: "Bid LandScape",
        meta: { alias: "Opt_BLC" }
      },
      {
        path: "/Optimization/QueryAsinGraph",
        component: () => import("@!/views/Research/QueryItemGraph/index.vue"),
        meta: {
          title: "Query-Item Graph",
          id: 1,
          isShowTime: true,
          showCompareCheck: false,
          alias: "Opt_QAG",
          breadcrumb: [
            {
              name: "Query-Item Graph",
              link: "/Optimization/QueryAsinGraph"
            }
          ]
        },
        name: "Query-Item Graph"
      },
      {
        path: "/Notification",
        component: () => import("@!/views/Notification/notification.vue"),
        meta: { title: "Notification", id: 2 }
      },
      {
        path: "/Campaign/BulkOperations",
        alias: "/Campaign/BulkOperations",
        component: () => import("@!/views/Advertising/BulkOperations/index.vue"),
        meta: {
          showCompareCheck: true,
          alias: "Ad_BO"
        },
        name: "AdvertisingBulkOperations"
      },
      {
        path: "/Campaign/BulkOperationsLog",
        component: () => import("@!/views/Advertising/BulkOperations/log.vue"),
        meta: {
          showCompareCheck: true,
          alias: "Ad_BO"
        },
        name: "AdvertisingBulkOperationsLog"
      },
      {
        path: "/Campaign/BulkOperationsDetail",
        component: () => import("@!/views/Advertising/BulkOperations/logDetail.vue"),
        meta: {
          showCompareCheck: true,
          alias: "Ad_BO"
        }
      },
      {
        path: "/Campaign/BulkOperationsPreview",
        component: () => import("@!/views/Advertising/BulkOperations/preview.vue"),
        meta: {
          showCompareCheck: true,
          alias: "Ad_BO"
        }
      },
      {
        path: "/Campaign/BulkOperationResult",
        component: () => import("@!/views/Advertising/BulkOperations/result.vue"),
        meta: {
          showCompareCheck: true,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/CampaignTagging/Detail",
        alias: "/Campaign/CampaignTagging/Detail",
        name: "Campaign Tag Detail",
        component: () => import("@!/views/Advertising/AdvertisingDetail/Advertising.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: false,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Keyword/Detail",
        alias: "/Campaign/Keyword/Detail",
        name: "Keyword Detail",
        component: () => import("@!/views/Advertising/AdvertisingDetail/Advertising.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: false,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Keyword/KeywordAnalysis",
        alias: "/Campaign/Keyword/KeywordAnalysis",
        name: "Keyword Analysis",
        component: () => import("@!/views/Advertising/AdvertisingDetail/Advertising.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: false,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Item/ItemDetail",
        alias: "/Campaign/Item/ItemDetail",
        name: "Item Detail",
        component: () => import("@!/views/Advertising/AdvertisingDetail/Advertising.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: false,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Item/ItemHealth",
        alias: "/Campaign/Item/ItemHealth",
        name: "Item Health",
        component: () => import("@!/views/Advertising/AdvertisingDetail/Advertising.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: false,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Item/ItemAnalysis",
        alias: "/Campaign/Item/ItemAnalysis",
        name: "Item Analysis",
        component: () => import("@!/views/Advertising/AdvertisingDetail/Advertising.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: false,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Query/ItemAnalysis",
        alias: "/Campaign/Query/ItemAnalysis",
        name: "Search Term Detail",
        component: () => import("@!/views/Advertising/AdvertisingDetail/Advertising.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: false,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Detail",
        alias: "/Advertising/Detail",
        component: () => import("@!/views/Advertising/AdvertisingDetail/Advertising.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: false,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Profile",
        alias: "/Advertising/Profile",
        name: "CampaignProfile",
        component: () => import("@!/views/Advertising/Profile/index.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Campaign",
        alias: "/Advertising/Campaign",
        component: () => import("@!/views/Advertising/Campaign/index.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        },
        name: "Campaign"
      },
      {
        path: "/Campaign/Explorer",
        component: () => import("@!/views/Advertising/ExplorerSinglePage/index.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        },
        name: "Campaign Explorer"
      },
      {
        path: "/Campaign/DisplayItem",
        alias: "/Advertising/DisplayItem",
        component: () => import("@!/views/Advertising/DisplayItem/index.vue"),
        meta: {
          id: 1,
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        },
        name: "Display Item"
      },
      {
        path: "/AuditCenter",
        component: () => import("@!/views/Optimization/AuditCenter/pages/home/index.vue"),
        name: "Paid Search Audit"
      },
      {
        path: "/AuditCenter/Detail",
        component: () => import("@!/views/Optimization/AuditCenter/detail.vue"),
        name: "Audit Detail",
        meta: {
          parent: "Paid Search Audit"
        }
      },
      {
        name: "Super Wizard",
        path: "/Campaign/CampaignSuperCustom",
        component: () => import("@/views/CampaignSuperCustom/index.vue"),
        meta: {
          parent: "Campaign",
          alias: "Advertising"
        }
      },
      // {
      //   path: "/Account/UserManage",
      //   component: () => import("@!/views/Account/pages/UserManagement.vue")
      // },
      {
        path: "/Account/MyAccount",
        component: () => import("@!/views/Account/pages/MyAccount.vue"),
        name: "My Account"
      },
      {
        path: "/Guide",
        component: () => import("@!/views/Account/pages/Welcome.vue"),
        meta: {
          noBreadcrumb: true
        }
      },
      {
        path: "/Guidance",
        component: () => import("@!/views/Account/pages/Guidance.vue"),
        meta: {
          noBreadcrumb: true
        }
      },
      {
        path: "/Binding",
        component: () => import("@!/views/Account/pages/Authorize.vue"),
        meta: {
          noBreadcrumb: true
        }
      },
      {
        path: "/oauth2/redirect",
        component: () => import("@!/views/Account/pages/Authorize.vue")
      },
      {
        path: "/oauth2/vendorredirect",
        component: () => import("@!/views/Account/pages/Authorize.vue")
      },
      {
        path: "/Account/Setting",
        component: () => import("@!/views/Account/pages/Setting.vue"),
        name: "Settings"
      },
      {
        path: "/Campaign/KeywordTagging",
        alias: "/Advertising/KeywordTag",
        component: () => import("@!/views/Advertising/KeywordTag/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        },
        name: "KeywordTagging"
      },
      {
        path: "/Campaign/ItemTagging",
        alias: "/Advertising/ItemTag",
        component: () => import("@!/views/Advertising/ItemTag/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        },
        name: "ItemTagging"
      },
      {
        path: "/Campaign/CampaignTagging",
        alias: "/Advertising/CampaignTag",
        component: () => import("@!/views/Advertising/CampaignTag/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        },
        name: "CampaignTagging"
      },
      {
        path: "/Campaign/AdGroup",
        alias: "/Advertising/AdGroup",
        name: "Adgroup",
        component: () => import("@!/views/Advertising/Adgroup/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/ValidBids",
        alias: "/Advertising/ValidBids",
        name: "Valid Bids",
        component: () => import("@!/views/Advertising/ValidBids/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Keyword",
        alias: "/Advertising/Keyword",
        component: () => import("@!/views/Advertising/Targeting/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Item",
        alias: "/Advertising/Item",
        component: () => import("@!/views/Advertising/Item/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        },
        name: "AdvertisingItem"
      },
      {
        path: "/Campaign/ItemInsight",
        alias: "/Advertising/ItemInsight",
        name: "Item Insight",
        component: () => import("@!/views/Advertising/Item/ItemInsight/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: true,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/Query",
        alias: "/Advertising/Query",
        component: () => import("@!/views/Advertising/SearchTerm/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: false,
          alias: "Advertising"
        },
        name: "AdvertisingSearchTerm"
      },
      {
        path: "/Campaign/NegativeKeyword",
        component: () => import("@/views/Advertising/NegativeTargeting/index.vue"),
        name: "Negative Targeting",
        meta: {
          isShowTime: true,
          showCompareCheck: false,
          alias: "Advertising"
        }
      },
      {
        path: "/Campaign/CreateSPCampaign",
        component: () => import("../views/Advertising/Campaign/CreateCampaign/CreateSPCampaign.vue"),
        meta: {
          parent: "Campaign",
          isShowTime: false,
          alias: "Advertising"
        },
        name: "CreateSPCampaign"
      },
      {
        path: "/Campaign/CreateSBCampaign",
        component: () => import("../views/Advertising/Campaign/CreateCampaign/CreateSBCampaign.vue"),
        meta: {
          parent: "Campaign",
          isShowTime: false,
          alias: "Advertising"
        },
        name: "CreateSBCampaign"
      },
      {
        path: "/Campaign/CreateSVCampaign",
        component: () => import("../views/Advertising/Campaign/CreateCampaign/CreateSVCampaign.vue"),
        meta: {
          parent: "Campaign",
          isShowTime: false,
          alias: "Advertising"
        },
        name: "CreateSVCampaign"
      },
      {
        path: "/Advertising/CreateAdGroup",
        component: () => import("../views/Advertising/Adgroup/CreateAdGroup/index.vue"),
        meta: {
          parent: "Adgroup",
          isShowTime: false,
          alias: "Advertising"
        },
        name: "CreateAdGroup"
      },
      {
        path: "/Campaign/VideoManagement",
        component: () => import("@!/views/Advertising/Campaign/VideoManagement/index.vue"),
        name: "Video Management",
        meta: {
          parent: "Campaign",
          isShowTime: false,
          alias: "Advertising"
        }
      },
      {
        path: "Share/ShareLink",
        component: () => import("@!/views/Share/ShareLink.vue"),
        name: "Share Link"
      },
      {
        path: "/Share/ShareLinkConfig",
        component: () => import("@!/views/Share/SharelinkConfig.vue"),
        meta: {
          parent: "Share Link"
        }
      },
      {
        path: "CategoryIntelligence/SearchTerm",
        component: () => import("@!/views/CategoryIntelligence/SearchTerm.vue"),
        name: "SearchTerm",
        meta: {
          alias: "CI_ST"
        }
      },
      {
        path: "CategoryIntelligence/Product",
        component: () => import("@!/views/CategoryIntelligence/Product.vue"),
        name: "Product",
        meta: {
          alias: "CI_PD"
        }
      },
      {
        path: "CategoryIntelligence/BrandAndSales",
        component: () => import("@!/views/CategoryIntelligence/BrandAndSales.vue"),
        name: "BrandAndSales",
        meta: {
          menuMeta: [{ alias: "CI_BS", menuName: "Brand & Sales" }]
        }
      },
      {
        path: "CategoryIntelligence/ASINQuery",
        component: () => import("@!/views/CategoryIntelligence/ASINQuery.vue"),
        name: "ASINQuery",
        meta: { alias: "CI" }
      },
      {
        path: "CategoryIntelligence/ASINHistory",
        component: () => import("@!/views/CategoryIntelligence/ASINHistory.vue"),
        name: "ASINHistory",
        meta: { alias: "CI" }
      }
    ]
  },
  /* {
    path: "/Optimization/ProductAI",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "",
        component: () => import("@/views/SuperAi/index.vue"),
        meta: { title: "Home", id: 1, noBreadcrumb: true }
      }
    ]
  }, */
  // {
  //   path: "/Optimization/Bidexplorer",
  //   component: Main,
  //   meta: { auth: true, id: -1 },
  //   children: [
  //     {
  //       path: "",
  //       component: () => import("@/views/bidexplorer/index.vue"),
  //       meta: { title: "Bidexplorer", id: 1, noBreadcrumb: true }
  //     }
  //   ]
  // },
  {
    path: "/ManualTool/ProductAI",
    component: () => import("@!/views/SuperAi/manualSuperAI/index.vue")
  },
  {
    path: "/ManualTool/CampaignAI",
    component: () => import("@!/views/SuperAi/campaignAI/index.vue")
  }
]
export default routerRebuild(routes, true, "walmart")
